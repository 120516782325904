import './Home.scss'

import Header from '../../atoms/header/Header'
import Footer from '../../atoms/footer/Footer'

const Home = () => {
	return (
		<div className="Home">

			<Header />

			<h1>Welcome</h1>

			<p>Lifetoweb is a premier software development and consultancy company based in the heart of London. We specialize in creating innovative software solutions that drive business success and deliver exceptional user experiences. Our expert team combines technical prowess with strategic insights to provide comprehensive services tailored to your unique needs.</p>

			<p>For any enquiries, please contact us at <a href="mailto:hello@lifetoweb.com">hello@lifetoweb.com</a></p>

			<Footer />

		</div>
	)
}

export default Home
