import './Header.scss'
import logo from '../../../assets/images/logo.png'

const Header = () => {
	return (
		<header className="Header">
			<img src={logo} className="Header_Logo" alt="Lifetoweb" />
		</header>
	)
}

export default Header
