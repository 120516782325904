import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './ui/pages/home/Home'

const AppRouter = () => 
	<Router>
		<Routes>
			<Route exact path="/" element={<Home />} />
		</Routes>
	</Router>

export default AppRouter
