import './Footer.scss'

const Footer = () => {
	return (
		<footer className="Footer">
			<p>© 2024 Lifetoweb - Company Number 9877182</p>
		</footer>
	)
}

export default Footer
